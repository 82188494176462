@import url(./styles/responsive.css);
@import url(./styles/popup.css);
@import url(./App.css);
@import url(./styles/sidebar.css);
body {
  scrollbar-width: thin !important;
}
.BillingAddInputLabel {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}
.img_blocks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingEditInput {
  width: 100%;
  padding: 8px 0px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 2px 2px 0px #00000040;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: center;
  outline: none;
}

.billingMainInputBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bookingDateEditInput {
  width: 100%;
  padding: 8px 0px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 2px 2px 0px #00000040;
  font-family: Poppins;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: center;
}
.otherAmount {
  padding: 8px 0px;
  width: 90%;
  border: 1px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: center;
}
/* ************************************************* */
/* LOGIN FORM */
.mainSection {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  overflow: hidden;
}
.loginMainSection {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainBlock {
  width: 100%;
  height: 80vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.login-image {
  max-width: 100%;
  height: auto;
}
.login-head {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: rgb(29, 36, 138);
}
/* .billingFormSection {
  height: 100%;
  overflow: hidden;
} */

/* .billingFormSection{
 
  height: 100vh;
} */
.loginBtn {
  width: 100%;
  font-weight: 600 !important;
  font-size: 18px;
  padding: 10px 0px !important;
  border-radius: 5px !important;
  color: blue;
  border: none;
  background: white;
}
.loginBtnBlock {
  width: 100%;
}
.loginFormBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 30px 40px;
}

.loginBlock {
  background: #3e6fb9;
  /* width: 70%; */
  border-radius: 5px;
  color: #ffffff;
  padding: 8px;
  margin: 0px auto;
}
.login_input_container {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 4px;
  border: none;
  border-bottom: 1px solid white;
}
.login_input_password_container {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  width: 100%;
  border: none;
  border-bottom: 1px solid white;
}
.loginHeading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  padding: 12px 25px;
}
.loginImageBlock {
  width: 100%;
  height: 100%;
}
.loginSubHeading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  padding: 0px 40px;
}
.image_icon {
  width: 100%;
  height: 100%;
}
.login_image_icon {
  width: 100%;
  height: 100%;
}
.login-password-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.image_block {
  width: 10%;
  height: 10%;
}
.overview-download-block {
  width: 30%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #4e73de;
}
.crm-image {
  width: 100%;
  height: 100%;
}
.overview-btn {
  background: transparent;
  border: none;
  outline: none;
  /* width: 100%; */
  color: #ffffff;
  font-family: Roboto Flex;
  font-size: 12px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
}
.search-icon {
  width: 20px;
  height: 20px;
}
.overview-download-block:hover {
  background: #243975;
}
.login_input_field {
  color: white;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 3px;
  background: transparent;
}
.passwordBlock {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
input[type="email"]::placeholder {
  color: #ffffff;
}
.login_input_field::placeholder {
  color: #ffffff;
}
/* ************************************************** */
/* Login End */
/* Rent Bill */
.rentBillMainSection {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f5f5f581;
}
.rentBillHeading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 35.79px;
  color: black;
}

.--image-click {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.new-bill-head-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background: #ffffff;
}
.new-bill-search-block {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
  gap: 3px;
}
.billing-login-input-block {
  width: 100%;
}
.new-bill-heading-block {
  display: flex;
  align-items: center;
  /* width: 50%; */
}
.--new-room-dropdown {
  width: 100%;
}
.new-room-heading {
  display: flex;
  align-items: center;
  width: 50%;
}
.selectDropDown {
  width: 25%;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 42px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.selectDropDownNewRoom {
  width: 40%;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 42px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.selectDropDownHome {
  width: 25%;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 45px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.selectDropDown-viewBill {
  width: 35%;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 42px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.selectDropDown-viewBill.error {
  border: 1px solid red;
}
.rentalFormBlock {
  width: 85%;
  height: 82vh;
  background-color: transparent;
  color: #333333;
  border-radius: 8px;
  /* background: #ffffff; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: auto;
  /* scrollbar-width: thin; */
}
.fromUnitsBlock {
  width: 50%;
}

.toUnitsBlock {
  width: 50%;
}
.billingDateField {
  width: 80%;
  padding: 8px 0px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 2px 2px 0px #00000040;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: center;
}
.monthDatePicker {
  width: 90%;
  padding: 7px 0px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 2px 2px 0px #00000040;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
  line-height: 24.4px;
  text-align: center !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.formSubmit {
  width: 40%;
  height: 40px;
  border-radius: 5px;
  background-color: #0044cc;
  color: #ffffff;
  /* background: #4984dcd2; */
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  /* color: white; */
  outline: none;
}
.formSubmit:hover {
  background: #4984dc;
}
.logoutBlock {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.arrowBlock {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.billingInvoiceHeader {
  background-color: #6ec4d3;
}
.billingInvoiceHeading {
  font-size: 14px !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  padding: 8px 5px !important;
  text-align: center !important;
  /* border: 1px solid rgba(5, 5, 5, 0.479) !important; */
}
.billingInvoiceData {
  font-size: 12px !important;
  font-family: Poppins !important;
  font-weight: 400 !important;
  padding: 8px 5px !important;
  text-align: center !important;
  /* border: 1px solid rgba(0, 0, 0, 0.295) !important; */
}
.invoiceLogo {
  width: 120px;
  height: 120px;
}
.billingInvoiceLogoBlock {
  border-bottom: 1px solid rgba(0, 0, 0, 0.507);
  display: flex;
  justify-content: center;
  align-content: center;
}
.billingInputFieldBlock {
  width: 40%;
}
.billingInputLabelBlock {
  width: 20%;
}
.billingSubmitButton {
  display: flex;
  justify-content: end;
}
.billingSubHeadingBlock {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invoicePara {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  color: rgb(137, 115, 187);
}
.invoice_Para {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.logoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid blueviolet;
}
.pageSize {
  /* display: flex;
  width: 100%;
  justify-content: space-between; */
  align-items: center;
  /* flex-wrap: wrap; */
}
.tableData {
  height: 200px;
  width: 100%;
}
/* VIEW BILL START */

.view-bill-title-block {
  display: flex;
  align-items: center;
  /* width: 20%; */
}

.table-container {
  overflow: auto;
  scrollbar-width: thin;
  width: 100%;
}
.view-register-table-block {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
.--first-head {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.--last-head {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.view-bill-data {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  min-width: 130px;
  text-align: center;
  padding: 11px 0px !important;
}
.view-bill-head {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  background: #4e73de !important;
  color: white !important;
  padding: 11px 0px !important;
  min-width: 130px;
}
.viewBillMainSection {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  background: #f0f0f0;
  overflow: hidden;
}
.homeMainSection {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  background: #f5f7fb;
}
.view-bill-main-block {
  width: 95%;
  height: 64vh;
  border-radius: 10px;
  padding: 12px;
  overflow: auto;
  scrollbar-width: thin;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  margin: 6px 0px;
}
.view-bill-heading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24.4px;
}
.table-search {
  border: 1px solid rgba(0, 0, 0, 0.507);
  outline: none;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  width: 35%;
}
.view-bill-search-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  width: 70%;
}
.view-bill-search {
  border: 1px solid rgba(0, 0, 0, 0.507);
  outline: none;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: normal;
  width: 35%;
  padding: 0px 5px;
}
.view-bill-status {
  width: 30%;
  height: 42px;
  border-radius: 7px;
  border: none;
  outline: none;
  background: #4e73de;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.no-result {
  font-family: Roboto Flex;
  font-size: 18px;
  font-weight: 700;
}
.view-hostel-status {
  width: 30%;
  height: 42px;
  border-radius: 7px;
  border: none;
  outline: none;
  background: #4e73de;
  font-family: Roboto Flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.09px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.view-bill-status:hover {
  background-color: rgba(30, 30, 121, 0.89);
}
.view-hostel-status:hover {
  background-color: rgba(30, 30, 121, 0.89);
}

.--bill-search {
  width: 45%;
}
.arrow-btn {
  border: none;
  outline: none;
  /* background-color: #f8f4e1; */
  font-size: 20px;
  /* height: 25px; */
  width: 25px;
  text-align: center;
  cursor: pointer;
}
.power-amount {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}
.power-amount-input-block {
  width: 40%;
}
.new-bill-units-block {
  width: 80%;
  display: flex;
  align-items: center;
}
.new-bill-from-to-month {
  width: 20%;
}
.new-bill-from-to-label {
  width: 10%;
}

/* VIEW BILL END */
.register-heading-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 18px 15px;
  background: #ffffff;
}
.new-register-heading-block {
  background: #ffffff;
}
.registrationMainContainer {
  width: 100%;
  height: 100vh;
  background: #f0f0f0;
}
.register-form-main-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-room-form-main-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-form-block {
  width: 96%;
  height: calc(100vh - 120px);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  padding: auto 0px !important;
  overflow: auto;
  scrollbar-width: thin;
}
.new-room-form-block {
  width: 96%;
  height: 82vh;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  padding: auto 0px !important;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.room-form-section {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-register-title-block {
  display: flex;
  align-items: center;
  /* width: 50%; */
}
.view-register-btn {
  border-radius: 5px;
  width: 40%;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffff;
  border: none;
  outline: none;
  background: #4e72deee;
  height: 42px;
  cursor: pointer;
}
.view-register-title {
  display: flex;
  align-items: center;
  width: 30%;
}
.register-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24.4px;
  color: #4e73de;
}
.view-bill-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24.4px;
  color: #4e73de;
}
.view-customer-register {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffff;
  border: none;
  outline: none;
  background: #2e8b57;
  padding: 12px 0px;
  cursor: pointer;
  width: 25%;
}
.--view-register {
  width: 30%;
}

.selectDropDown.error {
  border: 1px solid red;
}
.newRegisterInput.error {
  border: 1px solid red;
}
.new-room-btn {
  border-radius: 5px;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: #ffff;
  border: none;
  outline: none;
  background: #4e72deee;
  cursor: pointer;
  width: 30%;
  height: 42px;
}

.new-room-btn:hover,
.view-register-btn:hover {
  background: #243975;
}
.new-register-input-block {
  width: 100%;
  /* border: 1px solid red; */
  /* justify-content: center; */
  display: flex;
  align-items: center;
  padding: 14px 16px;
  flex-wrap: wrap;
}
.new-room-input-block {
  width: 80%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  flex-wrap: wrap;
}
.newRegisterInput {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: left;
  border-radius: 5px;
  outline: none;
}

.new-room-box-field {
  width: 50%;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  outline: none;
}
.new-room-checkbox-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding: 5px;
}
.--new-register-label-two {
  width: 50%;
}
.newRegisterInputBlockTwo {
  width: 50%;
}
.loginregisterpassblock {
  width: 50%;
  padding: 8px;
  display: flex;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #ffffff;
}
.loginregisterpass {
  width: 100%;
  height: inherit;
  border: none;
  outline: none;
}

.register-submit-btn {
  width: 50%;
  height: 45px;
  border-radius: 5px;
  background: #4984dcd2;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #0000004d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: white;
  outline: none;
}
.register-submit-btn:hover {
  background: #4984dc;
}
.register-btn-block {
  width: 60%;
  display: flex;
  justify-content: end;
  padding: 0px 10px;
}
.register-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  flex-wrap: wrap;
  width: 50%;
}
.--new-room-container {
  width: 60%;
}
.--register-head {
  color: #4e73de;
}

.arrow-img-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.bill-rent-month {
  width: 80%;
  display: flex;
}
.bill-rent-month-input {
  width: 50%;
}
.billingInvoiceSection {
  width: 100%;
  height: 100%;
}
.invoice-download-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 0px 0px 0px;
  margin: 0px;
}
.billing-invoice-para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;

  /* margin: 2px; */
  /* padding: 0px; */
}
.billing-block {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  border-bottom: 2px solid black;
}
.--billing-para {
  /* padding-bottom: 5px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.507); */
}
.billing-logo {
  width: 140px;
  height: 140px;
}
.billing-invoice-logo-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  /* width: 100%; */
  /* width: 75%; */
}
.bill_home_pgg {
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* width: 100%; */
  /* width: 40%; */
}
.company_name_shopename {
  font-size: 22px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}
.billing-invoice-info-block {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 60%;
}
.billing-invoice-stu-block {
  width: 40%;
}
.billing-invoice-acc-block {
  width: 60%;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
}
.new-room-head {
  display: flex;
  justify-content: end;
  width: 50%;
  gap: 5px;
}

.view-room-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-room-main-block {
  width: 95%;
  height: 78vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  scrollbar-width: thin;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-top: 25px;
  padding: 30px 0px;
}
.view-bed-main-block {
  width: 95%;
  height: 80vh;
  overflow: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  scrollbar-width: thin;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 15px 0px;
  background-color: #ffffff;
}
/* .--view-beds {
  align-items: center;
} */
.view-beds-head {
  display: flex;
  align-items: center;
}
.view-beds-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding-left: 3px;
  margin-left: 10px;
  color: #000000;
  /* border-bottom: 2px dotted black; */
}
.view-beds-heading-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #ffffff;
  padding: 18px;
}
.view-room-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.beds-card-block {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  scrollbar-width: thin;
}
.view-beds-add-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-block {
  width: 18rem;
  height: 11rem !important;
  margin: 10px;
  cursor: pointer;
  background-color: #07425dc2 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.card-content-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.card-block:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.--view-beds-card {
  width: 21rem;
  height: 15rem !important;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.--view-beds-card:hover {
  transform: scale(1.05);

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.card-content-para {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: white !important;
  padding: 0px;
  margin: 0px;
}
.card-content-ac-type {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  color: white !important;
  padding: 0px;
  margin: 0px;
}
.hostel-status {
  font-weight: 600;
  font-family: Poppins;
  font-size: 14px;
  line-height: normal;
}
.--room-type {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.view-room-head-new-btn-block {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}
.--loginRegisterBlock {
  padding: 10px 20px;
}
.bill-status-bar {
  text-align: center;
  padding: 15px;
  background: #ffffff;
  border: 1.5px solid #d5c9f6;
  cursor: pointer;
  width: 33%;
}
.status-row-bar {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 5px 0px;
}
.status-row-bar-view-room {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  width: 90%;
}
.status-bar-block {
  width: 100%;
  display: flex;
  justify-content: center;
}
.--first-bar {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.--last-bar {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bill-status-content {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #919191;
  margin: 1px;
}
.bill-status-count {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #000000;
  margin: 1px;
}

.transaction-container {
  width: 50%;
  max-width: 450px;
  height: auto; /* You can specify a fixed height if needed */
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* th:nth-child(3), td:nth-child(3) {
  width: 80px;
} */

/* td, th {
  padding: 5px;
} */
td {
  word-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .logoBlock {
    flex-direction: column;
  }
  /* table {
    width: 100%;
    font-size: 12px;
  }
  th,
  td {
    padding: 8px;
    display: block;
    width: 100%;
    text-align: left;
  }
  th {
    background-color: #00bcd4; 
  } */
}
