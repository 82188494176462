.newRoomInputLabel {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
}
.newRoomInputField {
  width: 100%;
  padding: 10px 5px;
  background: #ffffff;
  border: 1px solid #0000004d;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.4px;
  text-align: left;
  border-radius: 5px;
  outline: none;
}
.new-room-para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #4e73de;
  padding: 0px 10px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.datefilter {
  width: 100%;
  max-width: none;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  outline: none;
  font-weight: 400;
  height: 42px;
  line-height: 22.78px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 1px solid #ccc;
}
.daterange-viewbill {
  width: 35%;
}
.datefilter::placeholder {
  font-size: 12px; /* Adjust the font size as needed */
}
.room-container {
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 1px;
  border: 1px solid rgba(30, 30, 121, 0.89);
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}
.room-container:hover {
  background-color: rgba(21, 21, 87, 0.89);
  color: white;
}
.newRoomInputBlockContainer {
  height: 43vh;
  border: 1px dotted black;
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: thin;
  padding: 5px;
  background-color: #f5f5f581;
}
.upload-file-block {
  display: flex;
  align-items: center;
  background: #b9d6c366;
  border: 2px dotted #004bcb66;
  width: 78%;
  height: 48px;
  padding: 7px 3px;
  text-align: center;
  border-radius: 5px;
  justify-content: space-around;
  cursor: pointer;
}
.upload-file-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}
.upload_icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.react-datepicker__input-container {
  position: static !important;
}
.camera-btn {
  z-index: 1;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #004bcb66;
  width: "30%";
  height: 40px;
  padding: 0px 8px;
  margin: 3px 0px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}
.camera-btn:hover {
  background-color: #243975;
  /* border:1px solid #ffffff ; */
  color: #ffffff;
}
.home-page-card {
  width: 20rem;
  height: 11rem !important;
  background: #ffffff !important;
  margin: 10px 25px;
  
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: background-color 0.2s ease;
}
.home-page-card:hover {
  background-color: #91a4b33f !important;
}
.homePageBlock {
  height: calc(100vh - 200px);
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home-content-para {
  font-family: Poppins;
  line-height: normal;
  text-align: left;
  color: #939ba2;
  font-size: 0.925rem;
  font-weight: 600;
}
.card-image-block {
  align-items: center;
  background: #d3e2f7;
  border-radius: 50%;
  color: #3b7ddd;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}
.card-image-block:hover{
  background: #84b5f8;
}
.status-para {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 3px 0px;
}
.badge-primary-light {
  color: #3b7ddd;
  padding: 0px 10px;
}
.badge-danger-light{
  color: #dc3545;
  padding: 0px 10px;
}